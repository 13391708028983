const countriesAmerica = [
  { name: 'Argentina', country_code: 'AR' },
  { name: 'Bolivia', country_code: 'BO' },
  { name: 'Brasil', country_code: 'BR' },
  { name: 'Canadá', country_code: 'CA' },
  { name: 'Chile', country_code: 'CL' },
  { name: 'Colombia', country_code: 'CO' },
  { name: 'Costa Rica', country_code: 'CR' },
  { name: 'Cuba', country_code: 'CU' },
  { name: 'República Dominicana', country_code: 'DO' },
  { name: 'Ecuador', country_code: 'EC' },
  { name: 'El Salvador', country_code: 'SV' },
  { name: 'Guayana Francesa', country_code: 'GF' },
  { name: 'Guatemala', country_code: 'GT' },
  { name: 'Guyana', country_code: 'GY' },
  { name: 'Honduras', country_code: 'HN' },
  { name: 'Haití', country_code: 'HT' },
  { name: 'Jamaica', country_code: 'JM' },
  { name: 'México', country_code: 'MX' },
  { name: 'Nicaragua', country_code: 'NI' },
  { name: 'Panamá', country_code: 'PA' },
  { name: 'Paraguay', country_code: 'PY' },
  { name: 'Perú', country_code: 'PE' },
  { name: 'Surinam', country_code: 'SR' },
  { name: 'Trinidad y Tobago', country_code: 'TT' },
  { name: 'Estados Unidos', country_code: 'US' },
  { name: 'Uruguay', country_code: 'UY' },
  { name: 'Venezuela', country_code: 'VE' }
]

export const salesData = [
  { country: 'Factura Internacional',Q1: 349600,Q2: 649600 , Q3: 1254000, Q4: 1527600  },
  { country: 'Perú', Q1: 44495, Q2: 46311, Q3: 94741, Q4: 117140 },
  { country: 'Colombia', Q1: 33021, Q2: 34369, Q3: 70310, Q4: 86933 },
  { country: 'Guatemala', Q1: 5000, Q2: 5000, Q3: 10000, Q4: 10000 },
  { country: 'Costa Rica', Q1: 39922, Q2: 41551, Q3: 85003, Q4: 105100 },
  { country: 'Argentina', Q1: 10799, Q2: 11240, Q3: 22994, Q4: 28430 },
  { country: 'Chile', Q1: 6315, Q2: 6573, Q3: 13447, Q4: 16626 },
  { country: 'Mexico', Q1: 10395, Q2: 10819, Q3: 22132, Q4: 27365 }
]

export const getCountryCode = (name) => {
  const country = countriesAmerica.find((country) => country.name === name)

  if (country) {
    return country.country_code
  }

  return '-'
}

export const getQSalesPercentByCountry = ({country, q, sum}) => {
  const sales = salesData.find((sale) => sale.country === country)
  const cleanValue = sum.replace(/[^0-9.]/g, '')

  const numericValue = parseFloat(cleanValue.replace(/,/g, ''))

  if (sales) {
    const qSales = sales[`Q${q}`]
    const percent = (numericValue / qSales) * 100
    return `${percent.toFixed(2)}%`
  }

  return '-'
  
}
