var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "centerx" },
    [
      _c(
        "vs-popup",
        {
          attrs: { title: "Documentación", active: _vm.poModalActive },
          on: {
            "update:active": function ($event) {
              _vm.poModalActive = $event
            },
          },
        },
        [
          _c(
            "vs-table",
            {
              attrs: { noDataText: "No data", data: _vm.poModalData },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ data }) {
                    return _vm._l(data, function (tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr },
                        [
                          _c(
                            "vs-td",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticStyle: { "padding-left": "10px" },
                                  attrs: { href: tr.file, target: "_blank" },
                                },
                                [_vm._v("\n              Ver\n            ")]
                              ),
                            ]
                          ),
                          _c("vs-td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.dateFormatter(tr.created_at)) +
                                "\n          "
                            ),
                          ]),
                        ],
                        1
                      )
                    })
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", [_vm._v("Archivo")]),
                  _c("vs-th", [_vm._v("Fecha creación")]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }