var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-card",
    { staticClass: "pt-4 flex flex-col justify-between h-full" },
    [
      _c(
        "header",
        {
          staticClass: "flex justify-between",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c("h3", { staticClass: "text-4xl font-bold" }, [
            _vm._v("\n            Objetivos anuales\n        "),
          ]),
          _c(
            "div",
            [_c("average-chart", { attrs: { average: _vm.average } })],
            1
          ),
        ]
      ),
      _c("vue-apex-charts", {
        ref: "apexChart",
        attrs: {
          type: "line",
          height: "500",
          width: "100%",
          options: _vm.chartOptions,
          series: _vm.series,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }