const monthlyOkr = [
  { monthNumber: 1, okr: 88750 },
  { monthNumber: 2, okr: 127952 },
  { monthNumber: 3, okr: 287792 },
  { monthNumber: 4, okr: 149957 },
  { monthNumber: 5, okr: 232754 },
  { monthNumber: 6, okr: 458155 },
  { monthNumber: 7, okr: 572330 },
  { monthNumber: 8, okr: 504459 },
  { monthNumber: 9, okr: 522573 },
  { monthNumber: 10, okr: 648364 },
  { monthNumber: 11, okr: 486186 },
  { monthNumber: 12, okr: 820727 }
]

const monthlyRealData = [
  { monthNumber: 1, real: 179000 },
  { monthNumber: 2, real: 216621 },
  { monthNumber: 3, real: 0 },
  { monthNumber: 4, real: 0 },
  { monthNumber: 5, real: 0 },
  { monthNumber: 6, real: 0 },
  { monthNumber: 7, real: 0 },
  { monthNumber: 8, real: 0 },
  { monthNumber: 9, real: 0 },
  { monthNumber: 10, real: 0 },
  { monthNumber: 11, real: 0 },
  { monthNumber: 12, real: 0 }
]

export const getRealData = () => {
  return monthlyRealData
}

export const getOkrData = () => {
  return monthlyOkr
}

export const getMonthOKR = (monthNumber) => {
  const foundOkr = monthlyOkr.find((month) => month.monthNumber === monthNumber)

  if (foundOkr) {
    return foundOkr.okr
  }
}

export const getToCurrentMonthOKR = () => {
  const indexCurrentMonth = new Date().getMonth() + 1

  return monthlyOkr
    .filter((okr) => okr.monthNumber <= indexCurrentMonth)
    .map((okr) => okr.okr)
}

export const getSumOKR = () => {
  return monthlyOkr.reduce((acc, curr) => acc + curr.okr, 0)
}

export const getSumRealData = (realData) => {
  return realData.reduce((acc, curr) => acc + curr, 0)
}
