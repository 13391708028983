import moment from "moment";

  //Get data from ODOO
  //billing
  export const idClientGetter = (params) => ('-')
  export const legalNameGetter = (params)=> ('-')
  export const billingMethodGetter = (params)=> ('-')
  export const paymentODOOMethodGetter = (params)=> ('-')
  export const timeLimitGetter = (params)=> ('-')
  export const agencyCommissionMethodGetter = (params)=> ('-')
  export const billingDateGetter = params => ('-');


  //charges
  export const billingNumberGetter = (params)=> ('-')
  export const expirationBillingDateGetter = params => ('-');
  export const latePaymentGetter = params => {
    const expirationBillingDate = params.data.due_date;

    if (!expirationBillingDate) return '-'

    // const expirationBillingDate = new Date('2024-09-10')
    const today = new Date()

    const diference = today - expirationBillingDate

    const daysUntilExpiration = Math.floor(diference / (1000 * 60 * 60 * 24));

    return daysUntilExpiration
  };
  

  //
  export const fullNameGetter = params => {
    const strName = `${params.data.billing_information_first_name || ''} ${params.data.billing_information_last_name || ''}`;
    const strNameTrim = strName.trim();
  
    return strNameTrim ? strName : (params.data.company_name ? params.data.company_name : '-');
  };
  
  export const emailGetter = params => (params.data.company_id ? params.data.email : '-');
  
  export const phoneGetter = params => (params.data.company_id ? params.data.phone : '-');
  
  export const countryGetter = params => (params.data.invoice_issuing_country_name ? params.data.invoice_issuing_country_name : `${params.data.billing_information_address_country || ''} ${params.data.billing_information_address_state || ''}`);
  
  export const campaignTypeNameGetter = params => (params.data.smart_campaign ? 'Programática' : 'Tradicional');
  
  export const agencyProfitGetter = params => parseFloat(params.data.total_price_with_profit - params.data.total_price_with_commission).toFixed(2);
  
  export const agencyProfitPercentagetoolTipValueGetter = params => {
    const totalValue = params.data.total_price_with_profit;
    const agencyProfit = parseFloat(params.data.total_price_with_profit - params.data.total_price_with_commission).toFixed(2);
    const profitPercentage = parseFloat((agencyProfit * 100) / totalValue).toFixed(2);
  
    return { profitPercentage: !isNaN(profitPercentage) ? profitPercentage : 0.0 };
  };
  
  export const complianceGetter = params => (params.data.total_shows_consumed || params.data.total_shows_consumed === 0 ? `${parseFloat((params.data.total_shows_consumed * 100) / params.data.total_promised_shows).toFixed(2)}%` : '-');
  
  export const platformProfitGetter = params => parseFloat(parseFloat(params.data.total_price_with_commission) - parseFloat(params.data.total_price) - parseFloat(params.data.withholding_tax_amount)).toFixed(2);
  
  export const latinAdProfitGetter = params => {
    const oldProfit = parseFloat(parseFloat(parseFloat(params.data.total_price_with_commission) - parseFloat(params.data.total_price) - parseFloat(params.data.withholding_tax_amount)) * params.data.percentage_profit_platform / 100).toFixed(2);
    const newProfit = parseFloat(parseFloat(params.data.total_price * (params.data.markup_platform / 100)) * (params.data.percentage_profit_platform / 100)).toFixed(2);
  
    return newProfit > 0 ? newProfit : oldProfit;
  };
  
  export const partnerProfitGetter = params => parseFloat(parseFloat(params.data.total_price * (params.data.markup_partner / 100))).toFixed(2);
  
  export const exchangeProfitGetter = params => parseFloat(parseFloat(params.data.total_price_with_profit * (params.data.markup_external_dsp / 100))).toFixed(2);
  
  export const platformProfitPercentagetoolTipValueGetter = params => {
    const totalValue = params.data.total_price_with_profit;
    const platformProfit = parseFloat(params.data.total_price_with_commission - params.data.total_price).toFixed(2);
    const profitPercentage = parseFloat((platformProfit * 100) / totalValue).toFixed(2);
  
    return { profitPercentage: !isNaN(profitPercentage) ? profitPercentage : 0.0 };
  };
  
  export const createdAtGetter = params => (params.data.created_at ? moment(params.data.created_at, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-');
  
  export const gatewayLastUpdateGetter = params => (params.data.gateway_last_update ? moment(params.data.gateway_last_update, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-');
  
  export const startDateGetter = params => (params.data.start_date ? moment(params.data.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-');
  
  export const endDateGetter = params => (params.data.end_date ? moment(params.data.end_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-');
  
  export const taxGetter = params => (params.data.tax ? `${parseFloat(params.data.tax)}%` : '-');
  
  export const withholdingTaxGetter = params => (params.data.withholding_tax ? `${parseFloat(params.data.withholding_tax)}%` : '-');
  
  export const dateFormatter = params => (params ? moment(params, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-');
  
  export const percentageFormatter = params => (params ? `${parseFloat(params)}%` : '-');
  
  export const currencyFormatter = params => {
    let value = params.value

    if (!value) return 0
    if (typeof value === 'string' && value.includes(','))
      value = value.replaceAll(',', '')
    var twoDecimals = parseFloat(value).toFixed(2)
    if (isNaN(twoDecimals)) return 'Número invalido'
    var formatPrice = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2
    }).format(twoDecimals)
    return `$ ${formatPrice}`
  };
  
  export const currencyCheck = params => (params.value ? currencyFormatter(params) : '-');
  
  export const paymentMethodGetter = params => {
    const gateway = {
      publinet: 'Publinet',
      mercadopago_basic: 'Mercadopago',
      mercadopago_custom: 'Mercadopago'
    };
  
    return params.data.gateway ? `${gateway[params.data.gateway]} (${params.data.payment_method})` : '-';
  };
  
  export const userNameGetter = params => `${params.data.first_name} ${params.data.last_name}`;
  
  export const companyNameGetter = params => (params.data.company_id ? `${params.data.company_name} (${params.data.company_id})` : '-');
  
  export const holdingIdGetter = params => (params.data.holding_id || '-');

  export const holdingNameGetter = params => (params.data.holding_id ? `${params.data.holding_name} (${params.data.holding_id})` : '-');
  
  export const campaignCostWithTaxGetter = params => parseFloat(parseFloat(params.data.total_price_with_profit) * (1 + (parseFloat(params.data.tax) / 100))).toFixed(2);
  
  export const amountReceivableWithTaxGetter = params => parseFloat(parseFloat(params.data.total_price_with_commission) * (1 + (parseFloat(params.data.tax) / 100))).toFixed(2);
  
  export const externalDspGetter = ({ data }) => (data.external_dsp_id ? `${data.external_dsp_name} (${data.external_dsp_exchange})` : 'LatinAd');