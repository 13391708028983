var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-apex-charts", {
    ref: "apexChart",
    attrs: {
      id: "average-chart",
      type: "radialBar",
      height: "200",
      width: "200",
      options: _vm.chartOptions,
      series: [_vm.average],
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }