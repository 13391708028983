<template>
    <vs-card class="pt-4 flex flex-col justify-between h-full">
        <header slot="header" class="flex justify-between">
            <h3 class="text-4xl font-bold">
                Objetivos anuales
            </h3>
            <div>
              <average-chart
                :average="average"
              />
            </div>
        </header>
        <vue-apex-charts ref="apexChart" type="line" height="500" width="100%" :options="chartOptions" :series="series" />
    </vs-card>
</template>

<script>
import chartConfigs from './chartConfigs.js'
import VueApexCharts from 'vue-apexcharts'
import AverageChart from '../average-chart/AverageChart.vue'
import _color from '@/assets/utils/color.js'


export default {
  components: {
    VueApexCharts,
    AverageChart
  },
  props: {
    series: {
      type: Array,
      required: true
    },
    average: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      chartOptions: null
    }
  },
  methods: {
    getHex (color) {
      if (_color.isColor(color)) {
        let rgb  = window.getComputedStyle(document.documentElement).getPropertyValue(`--vs-${color}`)
        rgb = rgb.split(',')
        return `#${  ((1 << 24) + (Number(rgb[0]) << 16) + (Number(rgb[1]) << 8) + Number(rgb[2])).toString(16).slice(1)}`
      }
      return color
    }
  },
  created () {
    try {
      this.chartOptions = Object.assign({}, chartConfigs.chartOptions)
    
      this.chartOptions['theme'] = {
        monochrome: {
          enabled: false,
          color: this.getHex(this.color),
          shadeTo: 'light',
          shadeIntensity: 0.65
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
}
</script>