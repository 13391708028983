<template>
    <div class="centerx">
      <vs-popup title="Documentación" :active.sync="poModalActive">
        <vs-table noDataText="No data" :data="poModalData">
          <template slot="thead">
            <vs-th>Archivo</vs-th>
            <vs-th>Fecha creación</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td style="display: flex; flex-direction: row;">
                <a style="padding-left: 10px;" :href="tr.file" target="_blank">
                  Ver
                </a>  
              </vs-td>
              <vs-td>
                {{ dateFormatter(tr.created_at) }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-popup>
    </div>
</template>

<script>
import { dateFormatter } from '../../utils/strings';

export default {
    name: "show-docs-popup",
    data () {
        return {
            dateFormatter
        }
    },
    props: {
        poModalActive: {
            type: Boolean,
            default: false
        },
        poModalData: {
            type: Array,
            default: null
        }
    },
    methods: {}
}


</script>