<template>
    <div class="vx-row items-center w-full pl-2 mb-4">
        <vs-avatar v-if="country.country !== 'Factura Internacional'" size="large" :src="`https://flagcdn.com/w80/${country.country_code.toLowerCase()}.png`"/>
        <vs-avatar v-else size="large" :src="worldwideFlag"/>
        <div class="ml-3">
            <h5 class="text-2xl">{{ country.sum }}</h5>
            <span class="text-xl">{{country.country}}</span>
        </div>
        <span :class="`${country.percent !== '-' && 'text-primary'} text-2xl flex-1 text-right`">{{ country.percent }}</span>
    </div>
</template>

<script>
export default{
  props: {
    country: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      worldwideFlag: require('@/assets/images/flags/worldwide.png')
    }
  }
}
</script>