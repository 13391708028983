var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vx-row items-center w-full pl-2 mb-4" },
    [
      _vm.country.country !== "Factura Internacional"
        ? _c("vs-avatar", {
            attrs: {
              size: "large",
              src: `https://flagcdn.com/w80/${_vm.country.country_code.toLowerCase()}.png`,
            },
          })
        : _c("vs-avatar", { attrs: { size: "large", src: _vm.worldwideFlag } }),
      _c("div", { staticClass: "ml-3" }, [
        _c("h5", { staticClass: "text-2xl" }, [
          _vm._v(_vm._s(_vm.country.sum)),
        ]),
        _c("span", { staticClass: "text-xl" }, [
          _vm._v(_vm._s(_vm.country.country)),
        ]),
      ]),
      _c(
        "span",
        {
          class: `${
            _vm.country.percent !== "-" && "text-primary"
          } text-2xl flex-1 text-right`,
        },
        [_vm._v(_vm._s(_vm.country.percent))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }