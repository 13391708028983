export default {
  chartOptions: {
    chart: {
      type: 'radialBar',
      fontFamily: 'Montserrat',
      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#e7e7e7',
          strokeWidth: '97%',
          margin: 5
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -2,
            fontSize: '28px'
          }
        }
      }
    },
    grid: {
      padding: {
        top: -25
      }
    },
    labels: ['Objetivo anual']
  }
}
