<template>
    <div>
        <vx-card>
            <div class="flex flex-wrap justify-between items-center">
                <!-- ITEMS PER PAGE -->
                <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
                    <vs-dropdown vs-trigger-click class="cursor-pointer">
                        <div
                            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                            <span class="mr-2">{{ GeneratePageSizeButton() }}</span>
                            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                        </div>
                        <vs-dropdown-menu>
                            <vs-dropdown-item @click="setPageSize(20)">
                                <span>20</span>
                            </vs-dropdown-item>
                            <vs-dropdown-item @click="setPageSize(50)">
                                <span>50</span>
                            </vs-dropdown-item>
                            <vs-dropdown-item @click="setPageSize(100)">
                                <span>100</span>
                            </vs-dropdown-item>
                            <vs-dropdown-item @click="setPageSize(150)">
                                <span>150</span>
                            </vs-dropdown-item>
                        </vs-dropdown-menu>
                    </vs-dropdown>
                </div>
                <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
                <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
                    <vs-input class="mb-4 md:mb-0 mr-4" v-model="searchQuery" @input="updateSearchQuery"
                        placeholder="Buscar..." />
                    <vs-button class="mb-4 md:mb-0 mr-4"
                        @click="gridApi.exportDataAsExcel({ rowHeight: 20 })">Exportar Excel</vs-button>
                    <slot name="extraHeaderActions"></slot>
                    <vx-tooltip text="Refrescar datos">
                        <vs-button class="mb-4 md:mb-0 mr-4" radius color="primary" type="border" icon-pack="feather"
                            icon="icon-refresh-cw" @click="updateData"></vs-button>
                    </vx-tooltip>
                    <vx-tooltip color="dark" text="Limpiar filtros">
                        <vs-button radius color="danger" type="border" icon-pack="feather" icon="icon-x"
                            @click="resetFilters"></vs-button>
                    </vx-tooltip>
                </div>
            </div>
            <ag-grid-vue 
                ref="agGridTable" 
                :gridOptions="gridOptions"
                class="ag-theme-material w-100 my-4 ag-grid-table" 
                :columnDefs="columns"
                :defaultColDef="defaultColDef" 
                :rowData="data" 
                rowSelection="multiple"
                rowMultiSelectWithClick="true" 
                :enableRangeSelection="true" 
                colResizeDefault="shift" 
                :animateRows="true"
                :getContextMenuItems="getContextMenuItems" 
                :pagination="true"
                :paginationPageSize="paginationPageSize" 
                :suppressPaginationPanel="true" 
                tooltipShowDelay="0"
                @selection-changed="onSelection" 
                :frameworkComponents="frameworkComponents"
            >
            </ag-grid-vue>
            <vs-pagination 
                :total="totalPages" 
                :max="maxPageNumbers" 
                v-model="currentPage"
                @input="onPageChange"
            />
        </vx-card>
    </div>
</template>
<script>
import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey(
    'CompanyName=Servoy B.V.,LicensedApplication=Servoy,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=200,AssetReference=AG-010463,ExpiryDate=11_October_2021_[v2]_MTYzMzkwNjgwMDAwMA==4c6752fe4cb2066ab1f0e9c572bc7491'
)

import { AgGridVue } from 'ag-grid-vue'
import _spanishLocaleText from '@/constants/spanishLocaleText.js'

export default {
    name: 'ssr-ag-table',
    components: {
        AgGridVue
    },
    props: {
        initialRenderered: {
            type: Boolean,
            default: true
        },
        data: {
            type: [],
            required: true
        },
        totalRows: Number,
        totalPages: Number,
        columns: {
            type: [],
            required: true
        },
        frameworkComponents: Object,
        isLoading: false,
    },
    data() {
        return {
            filters: {},
            isInitialized: false,
            searchQuery: '',
            gridApi: null,
            maxPageNumbers: 7,
            gridOptions: {
                localeText: _spanishLocaleText.getText()
            },
            defaultColDef: {
                sortable: true,
                editable: false,
                resizable: true,
                suppressMenu: true,
                headerHeight: 10
            },
            countSelected: 0,
            selectedRows: [],
            currentPage: 1,
            paginationPageSize: 20,
        }
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 576) {
                this.maxPageNumbers = 4
                this.gridOptions.columnApi.setColumnPinned('email', null)
            } else {
                this.gridOptions.columnApi.setColumnPinned('email', 'left')
            }
        },
        isLoading(newLoadingData) {
            if (newLoadingData) return this.gridApi.showLoadingOverlay()
            this.gridApi.hideOverlay()
        }
    },
    computed: {},
    methods: {
        //funciona
        fetchGridData({page = 1, pageSize = this.paginationPageSize}) {
            this.$emit('fetchData', {
                page,
                pageSize,
            });
        },
        //funciona
        onPageChange(page) {
            if (this.isInitialized) {
                this.currentPage = page;
                this.fetchGridData({page});
            } else {
                this.isInitialized = true;
            }
        },
        resetFilters() {
            this.gridApi.setFilterModel(null)
            this.gridApi.setSortModel(null)
        },
        onSelection() {
            const selectedRows = this.gridApi.getSelectedRows()
            this.selectedRows = selectedRows

            // Emitir evento con la cantidad de items seleccionados
            this.$emit('updateSelectedItems', this.selectedRows)
        },
        getContextMenuItems() {
            const selectedItems = this.gridApi.getSelectedNodes()
            const thereAreSelectedItems =
                Array.isArray(selectedItems) && selectedItems.length
            return [
                'copy',
                'copyWithHeaders',
                {
                    name: 'Exportar seleccionados',
                    icon: '<span class="ag-icon ag-icon-save" unselectable="on"></span>',
                    disabled: !thereAreSelectedItems,
                    subMenu: [
                        {
                            name: 'CSV (.csv)',
                            action: () => {
                                this.gridApi.exportDataAsCsv({
                                    onlySelected: true,
                                    columnSeparator: ';'
                                })
                            }
                        },
                        {
                            name: 'Excel (.xlsx)',
                            action: () => {
                                this.gridApi.exportDataAsExcel({ onlySelected: true })
                            }
                        }
                    ]
                }
            ]
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        updateData() {
            this.fetchGridData({page: this.currentPage, pageSize: this.paginationPageSize})
            // this.$emit('fetchData', {
            //     page: this.currentPage,
            //     pageSize: this.paginationPageSize,
            // });
        },
        setPageSize(size){
            this.currentPage = 1  // Reset current page to 1 when changing page size
            this.paginationPageSize = size
            this.fetchGridData({page: this.currentPage, pageSize: size})
        },
        GeneratePageSizeButton() {
            if (this.loadingData) return '...'

            const firstNumberCurrentPage =
                this.currentPage * this.paginationPageSize -
                (this.paginationPageSize - 1)

            const LastNumberCurrentPage =
                this.totalRows -
                    this.currentPage * this.paginationPageSize >
                    0
                    ? this.currentPage * this.paginationPageSize
                    : this.totalRows

            const total = this.totalRows

            return `${firstNumberCurrentPage} - ${LastNumberCurrentPage} of ${total}`
        }
    },
    beforeMount() { },
    mounted() {
        this.gridApi = this.gridOptions.api
        if(this.initialRenderered){
            this.fetchGridData({page: this.currentPage, pageSize: this.paginationPageSize});
        }
    }
}
</script>